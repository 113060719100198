import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Button } from '@dv01-inc/waterfall-ui';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Button order</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Button alignment</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Labels</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Variations</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Code</AnchorLink>
      <AnchorLink mdxType="AnchorLink">References</AnchorLink>
    </AnchorLinks>
    <h2>{`General guidance`}</h2>
    <p><strong parentName="p">{`Buttons are used to initialize an action.`}</strong>{` Button labels express what action will occur when the user interacts with it.`}</p>
    <p><strong parentName="p">{`Buttons are used primarily for actions.`}</strong>{` Examples include Add, Save, Delete, and Sign up. Each page should have one primary button, and a `}<strong parentName="p">{`Return`}</strong>{` or `}<strong parentName="p">{`Enter`}</strong>{` keyboard event should trigger it. Any remaining calls-to-action should be represented as secondary buttons.`}</p>
    <p><strong parentName="p">{`Do not use buttons as navigational elements.`}</strong>{` Instead, use `}<a parentName="p" {...{
        "href": "/components/link/code"
      }}>{`links`}</a>{` when the desired action is to take the user to a new page.`}</p>
    <p><strong parentName="p">{`Primary buttons always appear to the right.`}</strong>{` Secondary buttons appear to the left of the primary button.`}</p>
    <h2>{`Button order`}</h2>
    <p>{`When using multiple buttons, the primary button appears to the right and any secondary buttons appear to the left. Research has shown that performance differences between secondary and primary button placement are negligible, however `}<a parentName="p" {...{
        "href": "https://www.nngroup.com/articles/ok-cancel-or-cancel-ok/"
      }}>{`maintaining consistency`}</a>{` throughout a product, offering, or platform is crucial. The Secondary / Primary button order (secondary to the left and primary to the right) is therefore our required guidance and should be followed at all times.`}</p>
    <h2>{`Button alignment`}</h2>
    <p>{`Button alignment depends on where the buttons appear.`}</p>
    <ul>
      <li parentName="ul">{`Use left-justified buttons for forms on their own page (not in a modal or side panel).`}</li>
      <li parentName="ul">{`Use right-justified buttons if the primary response advances the user through a multi-step configuration such as a wizard.`}</li>
    </ul>
    <h2>{`Labels`}</h2>
    <p>{`Button labels should clearly indicate the action of the button. Use active verbs, such as `}<strong parentName="p">{`Add`}</strong>{` or `}<strong parentName="p">{`Delete`}</strong>{`. Use sentence-style capitalization (only the first word in a phrase and any proper nouns capitalized) and no more than three words for button labels.`}</p>
    <p>{`For sets of buttons, use specific labels, such as `}<strong parentName="p">{`Save`}</strong>{` or `}<strong parentName="p">{`Discard`}</strong>{`, instead of using `}<strong parentName="p">{`OK`}</strong>{` and `}<strong parentName="p">{`Cancel`}</strong>{`. This is particularly helpful when the user is confirming an action.`}</p>
    <p>{`For consistency, see `}<a parentName="p" {...{
        "href": "/guidelines/content/glossary"
      }}>{`labels and idioms`}</a>{` for the approved list of action labels.`}</p>
    <h2>{`Variations`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Button type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Purpose`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Primary`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For the principal call to action on the page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Secondary`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For secondary actions on each page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Button with icon`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When words are not enough, icons can be used in buttons to better communicate what the button does. Icons are always paired with text.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Disabled button`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use when the user cannot proceed until an input is collected.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Set of buttons`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When an action required by the user has more than one option, always use a a negative action button (secondary) paired with a positive action button (primary) in that order. Negative action buttons will be on the left; positive action buttons on the right. When these two types of buttons are paired in the correct order, they will automatically space themselves apart.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Small button`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use when there is not enough vertical space for a regular sized button.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Ghost button`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When an action does not require primary dominance on the page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Danger button`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When an action has potentially destructive effects on the user‘s data (delete, remove, etc).`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Code`}</h2>
    <Row mdxType="Row">
  <Column colMd={3} colLg={3} mdxType="Column">
    <Button label="Primary Button" mdxType="Button" />
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "path=src/button src=https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx",
            "path": "src/button",
            "src": "https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx"
          }}>{`<Button label="Primary Button" />
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={3} colLg={3} mdxType="Column">
    <Button variant="secondary" label="Secondary Button" mdxType="Button" />
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "path=src/button src=https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx",
            "path": "src/button",
            "src": "https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx"
          }}>{`<Button variant="secondary" label="Secondary Button" />
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={3} colLg={3} mdxType="Column">
    <Button label="Default Button" variant="default" mdxType="Button" />
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "path=src/button src=https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx",
            "path": "src/button",
            "src": "https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx"
          }}>{`<Button label="Default Button" variant="default" />
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={3} colLg={3} mdxType="Column">
    <Button label="Outline Button" variant="outline" mdxType="Button" />
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "path=src/button src=https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx",
            "path": "src/button",
            "src": "https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx"
          }}>{`<Button label="Outline Button" variant="outline" />
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={3} colLg={3} mdxType="Column">
    <Button label="Warning Button" variant="warning" mdxType="Button" />
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "path=src/button src=https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx",
            "path": "src/button",
            "src": "https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx"
          }}>{`<Button label="Warning Button" variant="warning" />
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={3} colLg={3} mdxType="Column">
    <Button label="Danger Button" variant="danger" mdxType="Button" />
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "path=src/button src=https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx",
            "path": "src/button",
            "src": "https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx"
          }}>{`<Button label="Danger Button" variant="danger" />
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={3} colLg={3} mdxType="Column">
    <Button label="Ghost Button" variant="ghost" mdxType="Button" />
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "path=src/button src=https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx",
            "path": "src/button",
            "src": "https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx"
          }}>{`<Button label="Ghost Button" variant="ghost" />
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={3} colLg={3} mdxType="Column">
    <Button label="Small" size="small" mdxType="Button" />
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "path=src/button src=https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx",
            "path": "src/button",
            "src": "https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx"
          }}>{`<Button label="Small Button" size="small" />
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={3} colLg={3} mdxType="Column">
    <Button label="Medium" size="medium" mdxType="Button" />
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "path=src/button src=https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx",
            "path": "src/button",
            "src": "https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx"
          }}>{`<Button label="Medium Button" size="medium" />
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={3} colLg={3} mdxType="Column">
    <Button label="Large" size="large" mdxType="Button" />
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "path=src/button src=https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx",
            "path": "src/button",
            "src": "https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx"
          }}>{`<Button label="Large Button" size="large" />
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={3} colLg={3} mdxType="Column">
    <Button label="Full Width" fullWidth mdxType="Button" />
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx",
            "metastring": "path=src/button src=https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx",
            "path": "src/button",
            "src": "https://github.com/dv01-inc/waterfall-ui/tree/master/src/button/Button.tsx"
          }}>{`<Button label="Full Width Button" fullWidth />
`}</code></pre>
  </Column>
    </Row>
    <h2>{`References`}</h2>
    <ul>
      <li parentName="ul">{`Jakob Nielsen, `}<a parentName="li" {...{
          "href": "https://www.nngroup.com/articles/ok-cancel-or-cancel-ok/"
        }}><em parentName="a">{`OK-Cancel or Cancel-OK? The Trouble With Buttons`}</em></a>{` (2008)`}</li>
      <li parentName="ul">{`The Interaction Design Foundation, `}<a parentName="li" {...{
          "href": "https://www.interaction-design.org/literature/book/the-glossary-of-human-computer-interaction/fitts-s-law"
        }}><em parentName="a">{`The Glossary of Human Computer Interaction, Chapter 37`}</em></a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      